<template>
	<span>
		<template v-if="value">
			<div class="mark" :class="{rq:value == '01', cp:value == '02'}" v-if="['00', '01', '02'].indexOf(value) >= 0"></div>
			<SelectComp type="text" :value="value" cdId="PRO115"/>
		</template>
		<template v-else>-</template>
	</span>
</template>

<script>
export default {
	props: { value : String },
}
</script>