<template>
	<div class="main-contents">
		<div class="tit">이력서 및 기술등급 관리</div>
		<div class="search-box">
			<SelectComp title="이력서 등록여부" v-model="input.resmYn" list="Y:등록,N:미등록" :isAll="true"/><nbsp/>
			<SelectComp title="기술등급 평가유무" v-model="input.confirmYn" list="Y:평가완료,N:미평가" :isAll="true"/><nbsp/>
			<SelectComp title="기술등급순_학력기준" v-model="input.resumeSchoolTechGradeCd" cdId="PRO106" :isAll="true"/><nbsp/>
			<SelectComp title="기술등급순_자격기준" v-model="input.resumeLicenseTechGradeCd" cdId="PRO106" :isAll="true"/><nbsp/>
			<InputComp title="회원명" v-model="input.searchTxt" @keydown.enter="getList('1')"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getList('1')"/>
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="3%">
					<col width="5%">
					<col width="5%">
					<col width="9%">
					<col width="6%">
					<col width="5%">
					<col width="5%">
					<col width="4%">
					<col width="4%">
					<col width="4%">
					<col width="4%">
					<col width="4%">
					<col width="4%">
					<col width="4%">
					<col width="4%">
					<col width="6%">
					<col width="8%">
				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>회원명</th>
						<th>회원번호</th>
						<th>아이디</th>
						<th>가입일</th>
						<th>기술등급<br/>(학력기준)</th>
						<th>기술등급<br/>(자격기준)</th>
						<th>보유기술</th>
						<th>직무분야</th>
						<th>전문분야</th>
						<th>학력사항</th>
						<th>자격증</th>
						<th>교육사항</th>
						<th>증빙서류</th>
						<th>재직경력</th>
						<th>프로젝트경력</th>
						<th class="resume">이력서</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="list && list.length > 0">
						<!-- 최대 25건 조회 -->
						<tr v-for="(item, idx) in list" :key="item.mberSeq" :class="{new:isNew(item)}">
							<td class="score"><div class="new" v-if="isNew(item)" style="font-size:10px;">미평가</div>{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + idx + 1}}</td>
							<td class="score name" @click="popupMberInfo(item.mberSeq, 'tec')">{{item.mberNm}} &#9654;</td>
							<td class="score">{{item.mberSeq}}</td>
							<td class="score">{{item.loginId}}</td>
							<td class="score">{{item.joinDate | date('yyyy.MM.dd')}}</td>
							<td class="score"><grade-img type="SCHOOL_TECH_ADM" :grade="item.resumeSchoolTechGradeCd"/></td>
							<td class="score"><grade-img type="LICENSE_TECH_ADM" :grade="item.resumeLicenseTechGradeCd"/></td>
							<td class="score"><cfm-status :value="item.techLevelState"/></td>
							<td class="score"><cfm-status :value="item.dutyState"/></td>
							<td class="score"><cfm-status :value="item.expertState"/></td>
							<td class="score"><cfm-status :value="item.schoolState"/></td>
							<td class="score"><cfm-status :value="item.licenseState"/></td>
							<td class="score"><cfm-status :value="item.eduState"/></td>
							<td class="score"><cfm-status :value="item.attaFileState"/></td>
							<td class="score"><cfm-status :value="item.careerState"/></td>
							<td class="score"><cfm-status :value="item.projectState"/></td>
							<td class="score resume">
								<div class="btn" v-if="item.resmYn == 'Y'" @click="goResume(item)">이력서 검토 &#9654;</div>
								<div class="btn rq" v-else @click="reqResume(item)">등록요청 ▶</div>
							</td>
						</tr>
					</template>
					<tr v-else>
						<!-- 관련내용 없을 경우 -->
						<td colspan="17" class="none">등록된 회원이 없습니다!</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div><!-- //main-contents -->
</template>
<script>
import pagingComp from '@/components/PagingComp.vue';
import gradeImg from "@/components/highpro/GradeImg.vue";
import cfmStatus from "@/components/highpro/adm/ResumeCfmStatus.vue";

export default {
	components: { pagingComp, gradeImg, cfmStatus },
	data() {
		return {
			input : {
				resumeSchoolTechGradeCd: '',
				resumeLicenseTechGradeCd: '',
				resmYn: '',
				confirmYn: '',
				searchTxt: '',
			},
			list : {},
			pageInfo : {},
		};
	},
	mounted(){
		var keys = Object.keys(this.input);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.input[key] = this.$route.params[key];
			}
		}

		this.getList();
	},
	methods: {
        getList(div, isScroll = false){
			if(div) this.input.pageIndex = 1;
			
            this.$.httpPost('/api/mem/adm/prf/getResumeList', this.input)
                .then(res => {
                    this.list = res.data.list;
                    this.pageInfo = res.data.pageInfo;
                    if(isScroll) {
						window.scroll(0, 0);
					}
                }).catch(this.$.httpErrorCommon);
        },
		isNew(item){
			return item.resumeGradeAdmApprYn == 'N'
			//return new Date().add(-7, 'day') < this.$format.date(item.joinDate, 'date') ? true : false;
		},
		goPage(page){
			this.input.pageIndex = page;
			this.getList();
		},
		popupMberInfo(reqMberSeq, div){
			//console.log('popupMberInfo', reqMberSeq, div);
			this.$.popup('/adm/mem/MEM923P02', { reqMberSeq, div });
		},
		goResume(item){
			//console.log('goResume', item);
			var params = {
				tecMberSeq : item.mberSeq,
				caller : {
					name: this.$route.name,
					params: this.input,
				}
			}
			this.$router.push({name: 'MEM910M02', params });
		},
		reqResume(item){
			//console.log('reqResume', item);
			this.$.popup('/adm/mem/MEM910P02', {tecMberSeq: item.mberSeq})
				.then(res => {
					if(res){
						this.$.httpPost('/api/mem/adm/prf/reqResume', {recvMberSeq: item.mberSeq})
							.then(() => alert('등록요청을 완료하였습니다.'))
							.catch(this.$.httpErrorCommon);
					}
				});
		},
	},
};
</script>
