<template>
	<img :src="data.src" :alt="data.title" :title="data.title" @click="$emit('click', $event)"/>
</template>
<script>
export default {
	props: {
		type : String,
		grade : String,
		specialYn: String,
	},
	computed: {
		data(){
			var grade = this.grade;
			switch(this.type){
				case "TOT_EVAL": 
					switch(grade){
						case "A": return this.specialYn == 'Y' ? { title : 'A'		, src : '/images/yellow_star_05_s.png'} : { title : 'A'		, src : '/images/yellow_star_05.png'};
						case "B": return this.specialYn == 'Y' ? { title : 'B'		, src : '/images/yellow_star_04_s.png'} : { title : 'B'		, src : '/images/yellow_star_04.png'};
						case "C": return this.specialYn == 'Y' ? { title : 'C'		, src : '/images/yellow_star_03_s.png'} : { title : 'C'		, src : '/images/yellow_star_03.png'};
						case "D": return this.specialYn == 'Y' ? { title : 'D'		, src : '/images/yellow_star_02_s.png'} : { title : 'D'		, src : '/images/yellow_star_02.png'};
						case "E": return this.specialYn == 'Y' ? { title : 'E'		, src : '/images/yellow_star_01_s.png'} : { title : 'e'		, src : '/images/yellow_star_01.png'};
						default : return this.specialYn == 'Y' ? { title : '미평가'	, src : '/images/yellow_star_00_s.png'} : { title : '미평가' , src : '/images/yellow_star_00.png'};
					}
				case "TOT_SM_EVAL": 
					switch(grade){
						case "A": return this.specialYn == 'Y' ? { title : 'A'		, src : '/images/yellow_star_sm_05_s.png'} : { title : 'A'		, src : '/images/yellow_star_sm_05.png'};
						case "B": return this.specialYn == 'Y' ? { title : 'B'		, src : '/images/yellow_star_sm_04_s.png'} : { title : 'A'		, src : '/images/yellow_star_sm_04.png'};
						case "C": return this.specialYn == 'Y' ? { title : 'C'		, src : '/images/yellow_star_sm_03_s.png'} : { title : 'A'		, src : '/images/yellow_star_sm_03.png'};
						case "D": return this.specialYn == 'Y' ? { title : 'D'		, src : '/images/yellow_star_sm_02_s.png'} : { title : 'A'		, src : '/images/yellow_star_sm_02.png'};
						case "E": return this.specialYn == 'Y' ? { title : 'E'		, src : '/images/yellow_star_sm_01_s.png'} : { title : 'A'		, src : '/images/yellow_star_sm_01.png'};
						default : return this.specialYn == 'Y' ? { title : '미평가'	, src : '/images/yellow_star_sm_00_s.png'} : { title : 'A'		, src : '/images/yellow_star_sm_00.png'};
					}
				case "TOT_EVAL_ADM": 
					switch(grade){
						case "A": return this.specialYn == 'Y' ? { title : 'A'		, src : '/images/admin/yellow_star_05_s.png'} : { title : 'A'		, src : '/images/admin/yellow_star_05.png'};
						case "B": return this.specialYn == 'Y' ? { title : 'B'		, src : '/images/admin/yellow_star_04_s.png'} : { title : 'B'		, src : '/images/admin/yellow_star_04.png'};
						case "C": return this.specialYn == 'Y' ? { title : 'C'		, src : '/images/admin/yellow_star_03_s.png'} : { title : 'C'		, src : '/images/admin/yellow_star_03.png'};
						case "D": return this.specialYn == 'Y' ? { title : 'D'		, src : '/images/admin/yellow_star_02_s.png'} : { title : 'D'		, src : '/images/admin/yellow_star_02.png'};
						case "E": return this.specialYn == 'Y' ? { title : 'E'		, src : '/images/admin/yellow_star_01_s.png'} : { title : 'E'		, src : '/images/admin/yellow_star_01.png'};
						default : return this.specialYn == 'Y' ? { title : '미평가'	, src : '/images/admin/yellow_star_00_s.png'} : { title : '미평가'	, src : '/images/admin/yellow_star_00.png'};
					}
				case "PRON_EVAL": 
					switch(grade){
						case "A": return { title : 'A'		, src : '/images/pron_blue_star_05.png'};
						case "B": return { title : 'B'		, src : '/images/pron_blue_star_04.png'};
						case "C": return { title : 'C'		, src : '/images/pron_blue_star_03.png'};
						case "D": return { title : 'D'		, src : '/images/pron_blue_star_02.png'};
						case "E": return { title : 'E'		, src : '/images/pron_blue_star_01.png'};
						default : return { title : '미평가'	, src : '/images/pron_blue_star_00.png'};
					}
				case "CLIENT_EVAL_CST": 
					switch(grade){
						case "A": return { title : 'A'		, src : '/images/star05_cst_evl.png'};
						case "B": return { title : 'B'		, src : '/images/star04_cst_evl.png'};
						case "C": return { title : 'C'		, src : '/images/star03_cst_evl.png'};
						case "D": return { title : 'D'		, src : '/images/star02_cst_evl.png'};
						case "E": return { title : 'E'		, src : '/images/star01_cst_evl.png'};
						default : return { title : '미평가'	, src : '/images/star00_cst_evl.png'};
					}
				case "CLIENT_EVAL_ADM": 
					switch(grade){
						case "A": return { title : 'A'		, src : '/images/admin/green_star_05.png'};
						case "B": return { title : 'B'		, src : '/images/admin/green_star_04.png'};
						case "C": return { title : 'C'		, src : '/images/admin/green_star_03.png'};
						case "D": return { title : 'D'		, src : '/images/admin/green_star_02.png'};
						case "E": return { title : 'E'		, src : '/images/admin/green_star_01.png'};
						default : return { title : '미평가'	, src : '/images/admin/green_star_00.png'};
					}
				case "CLIENT_EVAL": 
					switch(grade){
						case "A": return { title : 'A'		, src : '/images/star05.png'};
						case "B": return { title : 'B'		, src : '/images/star04.png'};
						case "C": return { title : 'C'		, src : '/images/star03.png'};
						case "D": return { title : 'D'		, src : '/images/star02.png'};
						case "E": return { title : 'E'		, src : '/images/star01.png'};
						default : return { title : '미평가'	, src : '/images/star00.png'};
					}
				case "CLIENT_EVAL_SM": 
					switch(grade){
						case "A": return { title : 'A'		, src : '/images/tec/myp/green_star_05.png'};
						case "B": return { title : 'B'		, src : '/images/tec/myp/green_star_04.png'};
						case "C": return { title : 'C'		, src : '/images/tec/myp/green_star_03.png'};
						case "D": return { title : 'D'		, src : '/images/tec/myp/green_star_02.png'};
						case "E": return { title : 'E'		, src : '/images/tec/myp/green_star_01.png'};
						default : return { title : '미평가'	, src : '/images/tec/myp/green_star_00.png'};
					}
				case "SCHOOL_TECH": 
					switch(grade){
						case "04": return { title : '특급'	, src : '/images/ranking_sm_04.png'};
						case "03": return { title : '고급'	, src : '/images/ranking_sm_03.png'};
						case "02": return { title : '중급'	, src : '/images/ranking_sm_02.png'};
						case "01": return { title : '초급'	, src : '/images/ranking_sm_01.png'};
						default  : return { title : '미평가', src : '/images/ranking_sm_00.png'};
					}
				case "LICENSE_TECH": 
					switch(grade){
						case "05": return { title : '기술사', src : '/images/ranking_sm_05.png'};
						case "04": return { title : '특급'	, src : '/images/ranking_sm_04.png'};
						case "03": return { title : '고급'	, src : '/images/ranking_sm_03.png'};
						case "02": return { title : '중급'	, src : '/images/ranking_sm_02.png'};
						case "01": return { title : '초급'	, src : '/images/ranking_sm_01.png'};
						default  : return { title : '미평가', src : '/images/ranking_sm_00.png'};
					}
				case "LICENSE_TECH_DCO": 
					switch(grade){
						case "05": return { title : '기술사', src : '/images/admin/level_05.png'};
						case "04": return { title : '특급'	, src : '/images/admin/level_04.png'};
						case "03": return { title : '고급'	, src : '/images/admin/level_03.png'};
						case "02": return { title : '중급'	, src : '/images/admin/level_02.png'};
						case "01": return { title : '초급'	, src : '/images/admin/level_01.png'};
						default  : return { title : ''	, src : ''};
					}
				case "SCHOOL_TECH_ADM": 
					switch(grade){
						case "04": return { title : '특급'	, src : '/images/admin/level_04.png'};
						case "03": return { title : '고급'	, src : '/images/admin/level_03.png'};
						case "02": return { title : '중급'	, src : '/images/admin/level_02.png'};
						case "01": return { title : '초급'	, src : '/images/admin/level_01.png'};
						default  : return { title : '미평가', src : '/images/admin/level_00.png'};
					}
				case "LICENSE_TECH_ADM": 
					switch(grade){
						case "05": return { title : '기술사', src : '/images/admin/level_05.png'};
						case "04": return { title : '특급'	, src : '/images/admin/level_04.png'};
						case "03": return { title : '고급'	, src : '/images/admin/level_03.png'};
						case "02": return { title : '중급'	, src : '/images/admin/level_02.png'};
						case "01": return { title : '초급'	, src : '/images/admin/level_01.png'};
						default  : return { title : '미평가', src : '/images/admin/level_00.png'};
					}
				case "SCHOOL_TECH_MY": 
					switch(grade){
						case "04": return { title : '특급'	, src : '/images/tec/myp/level_ico_04.png'};
						case "03": return { title : '고급'	, src : '/images/tec/myp/level_ico_03.png'};
						case "02": return { title : '중급'	, src : '/images/tec/myp/level_ico_02.png'};
						case "01": return { title : '초급'	, src : '/images/tec/myp/level_ico_01.png'};
						default  : return { title : '미평가', src : '/images/tec/myp/level_ico_00.png'};
					}
				case "LICENSE_TECH_MY": 
					switch(grade){
						case "05": return { title : '기술사', src : '/images/tec/myp/level_ico_05.png'};
						case "04": return { title : '특급'	, src : '/images/tec/myp/level_ico_04.png'};
						case "03": return { title : '고급'	, src : '/images/tec/myp/level_ico_03.png'};
						case "02": return { title : '중급'	, src : '/images/tec/myp/level_ico_02.png'};
						case "01": return { title : '초급'	, src : '/images/tec/myp/level_ico_01.png'};
						default  : return { title : '미평가', src : '/images/tec/myp/level_ico_00.png'};
					}
				case "SCHOOL_TECH_NCS": 
					switch(grade){
						case "04": return { title : '특급'	, src : '/images/tec/prf/ranking_04.png'};
						case "03": return { title : '고급'	, src : '/images/tec/prf/ranking_03.png'};
						case "02": return { title : '중급'	, src : '/images/tec/prf/ranking_02.png'};
						case "01": return { title : '초급'	, src : '/images/tec/prf/ranking_01.png'};
						default  : return { title : '미평가', src : '/images/tec/prf/ranking_00.png'};
					}
				case "LICENSE_TECH_NCS": 
					switch(grade){
						case "05": return { title : '기술사', src : '/images/tec/prf/ranking_05.png'};
						case "04": return { title : '특급'	, src : '/images/tec/prf/ranking_04.png'};
						case "03": return { title : '고급'	, src : '/images/tec/prf/ranking_03.png'};
						case "02": return { title : '중급'	, src : '/images/tec/prf/ranking_02.png'};
						case "01": return { title : '초급'	, src : '/images/tec/prf/ranking_01.png'};
						default  : return { title : '미평가', src : '/images/tec/prf/ranking_00.png'};
					}
				case "INTERVIEW_RES": 
					switch(grade){
						case "04": return { title : ''	, src : ''};
						case "03": return { title : '인터뷰중'	, src : '/images/itv_mark_03.png'};
						case "02": return { title : '교체투입'	, src : '/images/itv_mark_02.png'};
						case "01": return { title : '불합격'	, src : '/images/itv_mark_01.png'};
						default  : return { title : '합격'		, src : '/images/itv_mark_00.png'};
					}
				case "INTERVIEW_RES_ADM" :
					switch(grade) {
						case "05": return { title : '투입'			, src : '/images/admin/itv_icon_05.png'};
						case "04": return { title : '인터뷰요청'	, src : '/images/admin/itv_icon_04.png'};
						case "03": return { title : '교체투입'		, src : '/images/admin/itv_icon_03.png'};
						case "02": return { title : '불합격'		, src : '/images/admin/itv_icon_02.png'};
						case "01": return { title : '합격'			, src : '/images/admin/itv_icon_01.png'};
						default  : return { title : ''	, src : ''};
					}
				case "INTERVIEW_RES_DCO" :
					switch(grade) {
						case "01": return { title : '불합격'		, src : '/images/admin/itv_icon_02.png'};
						case "00": return { title : '합격'			, src : '/images/admin/itv_icon_01.png'};
						default  : return { title : ''	, src : ''};
					}		
				case "INTERVIEW_RES_SCO" :
					switch(grade) {
						case "02": return { title : '불합격'		, src : '/images/admin/itv_icon_02.png'};
						case "01": return { title : '합격'			, src : '/images/admin/itv_icon_01.png'};
						default  : return { title : ''	, src : ''};
					}
				default : return { title : ''	, src : ''};
			}
		}
	}
}
</script>