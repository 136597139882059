<template>
    <div class="paging" :class="classNm">

        <div class="pager" v-if="pageInfo.pages != undefined">
            <ul>
                <li v-if="pageInfo.pageFirst > pageInfo.pageSize" class="btn_l_end first" @click="goPage(pageInfo.pageMin)"><img src="/images/page_arrow_left_end.png" alt="첫 페이지" /></li>						
                <li v-if="pageInfo.pageFirst > pageInfo.pageSize" class="btn_l first" @click="goPage(pageInfo.pageBefore)"><img src="/images/page_arrow_left.png" alt="이전 페이지" /></li>						
               
                <li v-for="page in pageInfo.pages" 
                    :key="page" 
                    :class="{over:page == pageInfo.pageIndex}"
                    @click="goPage(page)"
                >{{page}}</li>               

                <li v-if="pageInfo.pageNext < pageInfo.pageMax" class="btn_r" @click="goPage(pageInfo.pageNext)"><img src="/images/page_arrow_right.png" alt="다음 페이지" /></li>					
                <li v-if="pageInfo.pageNext < pageInfo.pageMax" class="btn_r_end" @click="goPage(pageInfo.pageMax)"><img src="/images/page_arrow_right_end.png" alt="마지막 페이지" /></li>
            </ul>
        </div>

    </div>
</template>

<script>
/**
 * 페이징 컴포넌트
 *      -> 검색결과 테이블 하단의 페이징를 노출 페이지번호 클릭시 페이지 번호를 emit으로 전송
 * 
 * 사용법)
 *      props   : 
 *                  pageInfo : 서버API 호출시 서버에서 내려준 pageInfo
 *      emit    :  
 *                  page-click : 페이지번호 클릭시 클릭한 페이지번호 전송 
 *
 * 예시)
 *      <pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
 * 
 */
export default {

    props: {
        pageInfo : {
            type : Object,
            default : () => {                
            }
        },
        classNm	: { type : String},
    },
    data() {
        return {
        }
    },

    beforeCreate() {
        // console.log('beforeCreate');
    },
    created() {
        // console.log('created');
    },
    beforeMount() {
        // console.log('beforeMount');
        // console.log(this.classNm);
        if(this.classNm) {
            this.pageInfo.pageMin = 1;
            this.pageInfo.pageMax = parseInt((this.pageInfo.totalRecordCount - 1)/this.pageInfo.pageUnit) + 1;
            this.pageInfo.pageFirst = parseInt((this.pageInfo.pageIndex-1) / this.pageInfo.pageSize) * this.pageInfo.pageSize + 1;
            this.pageInfo.pageBefore = (this.pageInfo.pageFirst>this.pageInfo.pageSize)? this.pageInfo.pageFirst - this.pageInfo.pageSize : this.pageInfo.pageMin;
            this.pageInfo.pageNext = (this.pageInfo.pageFirst+this.pageInfo.pageSize<this.pageInfo.pageMax)? this.pageInfo.pageFirst + this.pageInfo.pageSize : this.pageInfo.pageMax;
            
            // 페이지 배열 처리
            this.pageInfo.pages = [];
            var pageMax = (this.pageInfo.pageFirst + this.pageInfo.pageSize - 1 < this.pageInfo.pageMax)?this.pageInfo.pageFirst + this.pageInfo.pageSize-1 : this.pageInfo.pageMax;
            for(var i=this.pageInfo.pageFirst; i<=pageMax; i++){
                // console.log("i = " + i);
                this.pageInfo.pages.push( i );
            }
        }
        
    },
    mounted() {
        //console.log('pagecomp mounted');
    },
    beforeUpdate() {
        //console.log('pagecomp beforeUpdate');

        // 아래 4개 값은 메인화면에서 넘어온 값
        // this.pageInfo.pageIndex
        // this.pageInfo.pageSize
        // this.pageInfo.pageUnit
        // this.pageInfo.totalRecordCount
        
        // // 이전 페이지 및 다음페이지 계산
        this.pageInfo.pageMin = 1;
        this.pageInfo.pageMax = parseInt((this.pageInfo.totalRecordCount - 1)/this.pageInfo.pageUnit) + 1;
        this.pageInfo.pageFirst = parseInt((this.pageInfo.pageIndex-1) / this.pageInfo.pageSize) * this.pageInfo.pageSize + 1;
        this.pageInfo.pageBefore = (this.pageInfo.pageFirst>this.pageInfo.pageSize)? this.pageInfo.pageFirst - this.pageInfo.pageSize : this.pageInfo.pageMin;
        this.pageInfo.pageNext = (this.pageInfo.pageFirst+this.pageInfo.pageSize<this.pageInfo.pageMax)? this.pageInfo.pageFirst + this.pageInfo.pageSize : this.pageInfo.pageMax;
        
        // 페이지 배열 처리
        this.pageInfo.pages = [];
        var pageMax = (this.pageInfo.pageFirst + this.pageInfo.pageSize - 1 < this.pageInfo.pageMax)?this.pageInfo.pageFirst + this.pageInfo.pageSize-1 : this.pageInfo.pageMax;
        for(var i=this.pageInfo.pageFirst; i<=pageMax; i++){
            // console.log("i = " + i);
            this.pageInfo.pages.push( i );
        }

        // console.log("pageInfo", this.pageInfo);                 
    },
    updated() {
        // console.log('pagecomp update');
    },
    beforeDestroy() {
        // console.log('beforeDestroy');
    },
    destroyed() {
        // console.log('destroyed');
    },    


    methods : {
       goPage(page) {
            if(page != this.pageInfo.pageIndex) {
                this.$emit('page-click', page);
            }
       }
    }
    
}
</script>